import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'

const StyledDiv = styled.div`
  .text-shadow-blue {
    text-shadow: 0 3px 3px #003057;
  }
`

const StyledUnderline = styled.div`
  width: 80px;
  height: 4px;
  background: #A6192E;
`

const PageTeamSectionUpdateNew = () => (
  <>
    <div className="relative" style={{ display: "grid", background: "#423E42" }}>
      {/* You can use a GatsbyImage component if the image is dynamic */}
      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          // maxHeight: 600,
          objectFit: "cover",
          opacity: "0.4",
          height: 300,
        }}
        // This is a presentational image, so the alt should be an empty string
        alt="Team Page Hero Background"
        src={
          "../../images/backgrounds/home-hero-bg-1.jpg"
        }
        formats={["auto", "webp", "avif"]}
        width={1920}
        className="hidden md:block"
      />

      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          // maxHeight: 600,
          objectFit: "cover",
          opacity: "0.4",
          height: 300,
        }}
        // This is a presentational image, so the alt should be an empty string
        alt="Team Page Hero Background"
        src={
          "../../images/backgrounds/home-hero-bg-1.jpg"
        }
        formats={["auto", "webp", "avif"]}
        width={800}
        className="block md:hidden"
      />
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          placeItems: "center",
          display: "grid",
        }}
      >
        {/* Any content here will be centered in the component */}
        <div className='w-full max-w-screen-xl flex flex-col xl:flex-row items-center mx-auto'>
          <StyledDiv className='w-full md:w-10/12 xl:w-2/3 flex flex-col items-center mx-auto'>
            <h1 className='text-4xl lg:text-5xl font-medium text-white text-center text-shadow-blue uppercase mt-6'>
              <span className='leading-tight'>Our Team</span>
            </h1>

            <h2 className='w-full lg:w-9/12 md:text-xl text-white text-lg text-center text-shadow-blue my-6 px-4 md:px-0'><span className='leading-loose'>Our truly integrated team approach allows our leadership, property managers, accountants, and assistants to deliver the best in class service to our clients.</span></h2>
          </StyledDiv>
        </div>
      </div>
    </div>

    <div className='w-full bg-site-red'>
      <div className="w-11/12 py-16 mx-auto">
        <h2 className='font-display text-white text-4xl md:text-5xl font-bold leading-tight text-center'>Tiarna Principals</h2>
      </div>
    </div>

    <div className="w-full bg-site-blue">
      <div className='relative w-11/12 max-w-screen-xl py-12 md:py-16 lg:w-10/12 lg:py-20 mx-auto'>
        <div className='w-full grid grid-cols-1 xl:grid-cols-3 gap-12 md:gap-16 mx-auto'>
          <div className='flex flex-col items-center justify-center mb-6 md:mb-0'>
            <StaticImage
              alt="Thomas A. McAndrews"
              src={
                "../../images/team/Thomas_McAndrews_056.jpg"
              }
              formats={["auto", "webp", "avif"]}
              width={500}
              className="border-4 border-site-red"
            />

            <h2 className='font-display text-white text-xl font-bold center mt-3 mb-3 leading-tight text-center'>Thomas A. McAndrews,<br /> CPM®, CSM®</h2>

            <StyledUnderline className='mb-3'></StyledUnderline>

            <h3 className='text-white text-md center leading-relaxed text-center'>Chief Executive Officer</h3>
          </div>          

          <div className='flex flex-col items-center justify-center mb-6 md:mb-0'>
            <StaticImage
              alt="Robert S. Alleborn"
              src={
                "../../images/team/Scott_Alleborn_023.jpg"
              }
              formats={["auto", "webp", "avif"]}
              width={500}
              className="border-4 border-site-red"
            />

            <h2 className='font-display text-white text-xl font-bold center mt-3 mb-3 leading-tight text-center'>Robert S. Alleborn</h2>

            <StyledUnderline className='mb-3'></StyledUnderline>

            <h3 className='text-white text-md center leading-relaxed text-center'>Executive Vice President <br />&nbsp;</h3>
          </div>

          <div className='flex flex-col items-center justify-center mb-6 md:mb-0'>
            <StaticImage
              alt="Michael T. McAndrews"
              src={
                "../../images/team/Michael_McAndrews_035.jpg"
              }
              formats={["auto", "webp", "avif"]}
              width={500}
              className="border-4 border-site-red"
            />

            <h2 className='font-display text-white text-xl font-bold center mt-3 mb-3 leading-tight text-center'>Michael T. McAndrews,<br /> CPM®, CSM®</h2>

            <StyledUnderline className='mb-3'></StyledUnderline>

            <h3 className='text-white text-md center leading-relaxed text-center'>Executive Vice President</h3>
          </div>

          <div className='flex flex-col items-center justify-center mb-6 md:mb-0'>
            <StaticImage
              alt="Teresa E. Harvey"
              src={
                "../../images/team/Teresa_Harvey_Final.jpg"
              }
              formats={["auto", "webp", "avif"]}
              width={500}
              className="border-4 border-site-red"
            />

            <h2 className='font-display text-white text-xl font-bold center mt-3 mb-3 leading-tight text-center'>Teresa E. Harvey</h2>

            <StyledUnderline className='mb-3'></StyledUnderline>

            <h3 className='text-white text-md center leading-relaxed text-center'>Chief Financial Officer/Director of Human Resources</h3>
          </div>

          <div className='flex flex-col items-center justify-center mb-6 md:mb-0'>
            <StaticImage
              alt="Toan Nguyen"
              src={
                "../../images/team/Toan_Nguyen_016.jpg"
              }
              formats={["auto", "webp", "avif"]}
              width={500}
              className="border-4 border-site-red"
            />

            <h2 className='font-display text-white text-xl font-bold center mt-3 mb-3 leading-tight text-center'>Toan Nguyen, CPA</h2>

            <StyledUnderline className='mb-3'></StyledUnderline>

            <h3 className='text-white text-md center leading-relaxed text-center'>Controller <br />&nbsp;</h3>
          </div>

          <div className='flex flex-col items-center justify-center mb-6 md:mb-0'>
            <StaticImage
              alt="Randy Piehl"
              src={
                "../../images/team/Randy_Piehl_003.jpg"
              }
              formats={["auto", "webp", "avif"]}
              width={500}
              className="border-4 border-site-red"
            />

            <h2 className='font-display text-white text-xl font-bold center mt-3 mb-3 leading-tight text-center'>Randy Piehl, CPA</h2>

            <StyledUnderline className='mb-3'></StyledUnderline>

            <h3 className='text-white text-md center leading-relaxed text-center'>Controller <br />&nbsp;</h3>
          </div>
        </div>
      </div>
    </div>    

    <div className='w-full bg-site-red'>
      <div className="w-11/12 py-16 mx-auto">
        <h2 className='font-display text-white text-4xl md:text-5xl font-bold leading-tight text-center'>Tiarna Team</h2>
      </div>
    </div>

    <div className="w-full bg-gray-100">
      <div className='relative w-11/12 max-w-screen-xl py-12 md:py-16 lg:w-10/12 lg:py-20 mx-auto'>
        <div className="hidden md:block">
          <StaticImage
            alt="Tiarna Team Group Photo"
            src={
              "../../images/team/tiarna-team.jpg"
            }
            formats={["auto", "webp", "avif"]}
            width={1920}
          />
        </div>

        <div className="block md:hidden">
          <StaticImage
            // This is a presentational image, so the alt should be an empty string
            alt="Tiarna Team Group Photo"
            src={
              "../../images/team/tiarna-team.jpg"
            }
            formats={["auto", "webp", "avif"]}
            width={800}
          />
        </div>
      </div>
    </div>
  </>
)

export default PageTeamSectionUpdateNew