import * as React from "react"

import LayoutPage from "../components/layout/layout-page"
import PageTeamSectionUpdateNew from "../components/sections/page-team-section-update-new"
import Seo from "../components/seo"

const TeamPage = () => (
  <LayoutPage>
    <Seo title="Our Team" />

    <PageTeamSectionUpdateNew />
  </LayoutPage>
)

export default TeamPage
